.my-masonry-grid {
  display: flex;
  margin-left: -12px; /* adjust this value to change the space between items */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 0px; /* adjust this value to change the space between items */
  background-clip: padding-box;

}

/* Style your items */
.my-masonry-grid_column > div {
  margin-bottom: 0px;
  background-clip: padding-box;

}

.loader {
  border: 6px solid #d5d5d5;
  border-radius: 50%;
  border-top: 6px solid #5e5e5e;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}